@import url("https://fonts.googleapis.com/css?family=Averia+Serif+Libre|Metal+Mania&display=swap|Rye&display|Cormorant+Garamond|Gilda+Display|Great+Vibes|Noto+Serif+KR&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Euphoria+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200&display=swap");

.register-button {
	display: flex !important;
	align-items: center !important;
	padding: 3px 18px !important;
}

.landing-page-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

.navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
}

.navigation-logo {
	width: 100px;
	display: flex;
	align-items: center;
}

.navigation-buttons {
	display: flex;
	align-items: center;
}

.hero-image {
	max-width: 650px;
}

.hero-section {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.project-intro {
	color: white;
	max-width: 650px;
	padding: 20px;
}

.right-cta {
	background-size: cover;
	display: flex;
	align-items: center;
}

.leading-house-flag {
	max-width: 300px;
}

.footer-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #202123;
	flex-wrap: wrap;
	margin-top: 29px;
	color: whitesmoke;
	opacity: 0.8;
}

.credit {
	padding: 12px 10px 0 0;
	color: white;
}

.credit a {
	color: orange;
	padding-left: 4px;
}

.hogwarts-flags {
	max-width: 200px;
	padding-top: 15px;
}

.form-input-field,
.form-text-field {
	width: 100%;
	height: 50px;
	color: white;
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 20px;
	padding: 20px;
	margin: 10px 0;
	font-size: large;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.form-text-field {
	height: 150px;
	color: white;
}

.login-form-button {
	background: orange;
	border: 1px solid orange;
	padding: 15px;
	width: 100%;
	color: white;
	cursor: pointer;
	border-radius: 40px;
	font-size: 15px;
	transition: 600ms;
}

.login-form-button:hover {
	transition: 600ms;
	transform: scale(1.1);
}

.board-wrapper {
	display: flex;
	justify-content: right;
}

.open-board-button {
	background: orange;
	border: none;
	padding: 10px 15px;
	width: 160px;
	color: white;
	font-weight: 800;
	cursor: pointer;
	font-size: 15px;
	transition: 600ms;
	margin-bottom: 20px;
	border-radius: 5px;
}

.open-board-button:hover {
	transition: 600ms;
	transform: scale(1.1);
}

.user-login-page {
	min-height: 100vh !important;
	background-position: center;
	background-repeat: no-repeat;
}

.dashboard-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between !important;
	min-height: 100vh;
}

.user-dashboard {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

.current-boards {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0 20px;
	border-radius: 10px;
	margin: 20px;
}

.board-button,
.add-points-section {
	background-color: rgba(79, 79, 79, 0.765);
	border-radius: 10px;
	margin: 20px;
	color: rgb(85, 85, 85);
	padding-top: 10px;
}

.board-button a {
	color: black !important;
}

.logout-button {
	color: white;
	transition: 600ms;
	cursor: pointer;
	margin: 50px;
}

.logout-button:hover {
	transition: 600ms;
	transform: scale(1.1);
}

.create-new-board-plus {
	display: flex;
	justify-content: flex-end;
	margin-right: 20px;
}

.quidditch-game-board-title {
	font-size: 30px;
	text-align: left;
	margin: 0 0 20px;
}

.quidditch-game-board-description {
	max-width: 700px;
	color: white;
	font-size: 14px;
	text-align: left;
}

.board-elements {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.logout-icon {
	width: 35px;
}

.left-boards-container {
	display: flex;
	padding-bottom: 40 40px 0;
}

.left-boards-container span {
	padding: 40px;
}

.create-board-title {
	display: flex;
	align-items: center;
	margin: 0 20px;
	font-size: 20px;
}

:-ms-input-placeholder {
	color: white;
}

::-ms-input-placeholder {
	color: white;
}

.App {
	min-height: 100vh;
	margin: 0 auto;
	text-align: center;
	background-color: #000 !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-position: center;
	background-repeat: no-repeat;

	background: linear-gradient(rgba(72, 0, 72, 0.055), rgba(41, 13, 13, 0.504)),
		url(https://roywakumelojr.com/wp-content/uploads/2023/03/main-hpt-background.gif); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(rgb(72, 0, 72, 0.055), rgba(41, 13, 13, 0.504)),
		url(https://roywakumelojr.com/wp-content/uploads/2023/03/main-hpt-background.gif); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.landing-page {
	min-height: 85vh;
	display: flex;
	justify-content: space-between;
}

.landing-page-left-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.landing-page-right-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.landing-page-crests {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.App-link {
	color: #09d3ac;
}

.Logo {
	display: flex;
	align-items: left;
	width: 250px;
	height: 200px;
	padding: 20px;
}

@media (max-width: 800px) {
	.left-boards-container {
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: row !important;
	}

	.left-boards-container span {
		justify-content: center !important;
		align-content: center;
	}

	.Logo {
		width: 200px;
		height: 150px;
	}

	.hero-image {
		max-width: 80%;
		padding: 40px 0;
	}

	.footer-container {
		padding: 0 20px 10px;
	}

	.dashboard-profile-image {
		width: 80%;
		padding: 0;
	}

	.dashboard-elements {
		justify-content: left;
	}

	.current-boards {
		padding: 0;
		margin: 0;
	}

	.board-points-card {
		display: flex;
		justify-content: center;
	}

	.submit-points {
		padding: 10px 20px !important;
	}
}

h2 {
	color: white;
	text-align: center;
	margin: 10px;
}

.numbers {
	letter-spacing: 0.2rem;
	opacity: 0.9;
	color: white;
	text-align: center;
	margin: 1px;
	padding-bottom: 5px;
}

.house-points-title {
	padding-bottom: 20px;
}

.summary {
	font-size: 1.5rem;
	padding: 40px 40px;
	text-align: center;
	color: white;
}

.login-container,
.register-container {
	display: flex;
	justify-content: center;
}

.login-button {
	border-radius: 5px;
	letter-spacing: 0.2rem;
	padding: 5px 20px;
	color: white;
	border: 1px solid orange;
	transition: 600ms;
	cursor: pointer;
	margin: 5px;
}

.login-button:hover {
	transition: 600ms;
	transform: scale(1.1);
}

.top-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 100px;
}
.scores {
	display: flex;
	justify-content: flex-end;
	opacity: 0.8;
	box-sizing: border-box;
}

hr {
	margin-top: 40px;
}

.dashboard-scores {
	display: flex;
	justify-content: flex-end;
	text-align: center;
	opacity: 0.8;
	box-sizing: border-box;
}

.right-div {
	margin-top: 30px;
	overflow: hidden;
	box-sizing: border-box;
	display: block;
	align-items: center;
	background-color: black;
	height: 87vh;
	min-height: 740px;
	max-width: 350px;
	color: white;
}

.right-dashboard-div {
	display: flex;
	margin-top: -165px;
	background-color: black;
	width: 250px;
	color: white;
}

.Loader {
	display: flex;
	opacity: 0.8;
	padding-bottom: 20px;
	justify-content: center;
}

.dash-current-points {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.crests {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
}

.dash-crests {
	padding: 20px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.crest-image {
	width: 170px;
	height: 110px;
	padding: 0 20px;
	transition: 600ms;
}

@media only screen and (max-width: 420px) {
	.crest-image {
		width: 100px;
		height: 60px;
	}
}

.dash-crest-image {
	width: 230px;
	height: 130px;
	padding: 10px 40px;
	transition: 600ms;
}

.crest-icons {
	margin: 10px;
	opacity: 0.7;
	border-radius: 10px;
	transition: 600ms;
}

.crest-icons:hover {
	border-radius: 10px;
	transform: scale(1.1);
	transition: 600ms;
}

.login,
.register {
	margin-top: 40px;
}

.register-header {
	color: white;
}

.login-redirect {
	color: white;
	font-weight: 800 !important;
}

.login-redirect:hover {
	color: orange;
}

.dashboard-elements {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	opacity: 0.8;
	height: 100%;
	margin: 100px 20px;
}

.points-button {
	width: 140px;
}

.pointsAndButtonDiv {
	display: flex;
	padding-top: 5px;
}

.PointsForm {
	opacity: 0.8;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
}

.house-entry {
	text-align-last: center;
	margin: 5px;
	width: 180px;
}

.ui.fluid.dropdown {
	width: 250px;
}

.app-dashboard {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.dashboard-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.awarded-points {
	font-family: "Cookie", cursive;
	padding: 1px;
	margin-bottom: 1px;
}

.points-crests {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.reward-entry {
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 5;
}

.rewardCard {
	margin: 20px 5px;
	text-align: center;
	transition: 600ms;
}

.rewardCard:hover {
	transform: scale(1.1);
	transition: 600ms;
}

.editReturnText {
	color: white;
}

.crest-icons-dashboard {
	margin: 10px;
	border: 1px solid orange;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.466);
	transition: 600ms;
}

.crest-icons-dashboard:hover {
	transform: scale(1.1);
	transition: 600ms;
}

.points-entry {
	text-align-last: center;
	border-radius: 5px;
	padding-bottom: 40px;
	width: 100px;
	margin: 0 5px;
}

.plus-image {
	width: 40px;
	transition: 600ms;
	padding-bottom: 10px;
	padding-left: 5px;
}

.plus-image:hover {
	transform: scale(1.2);
	transition: 600ms;
}

.plusButton {
	display: flex;
	justify-content: center;
	opacity: 0.7;
	cursor: pointer;
	transition: 600ms;
}

.editButtons {
	display: flex;
	justify-content: space-evenly;
}

.add-points-form {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-bottom: 15px;
}

@media only screen and (max-width: 800px) {
	.landing-page {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.scores {
		display: flex;
		justify-content: center;
		opacity: 0.8;
		box-sizing: border-box;
	}

	h1 {
		margin-top: 0;
	}

	hr {
		margin-top: 30px;
		color: lightgray;
	}

	.summary {
		padding: 0 20px;
	}

	.Navigation {
		display: flex;
		justify-content: center;
		padding-left: 40px;
		margin: 0;
	}

	.crests {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding-top: 0;
		margin: 0;
	}

	.login-button {
		padding: 10px 100px;
	}
}

#root {
	position: relative;
	left: 0;
	bottom: 0;
	width: 100%;
}

.twitter-link {
	padding: 0 5px;
}

.website-link {
	padding: 0 10px;
}

.redirect-text {
	display: flex;
	justify-content: center;
	color: white;
	margin-top: 20px;
	letter-spacing: 0.1rem;
}

.HouseDropDown {
	width: 200px;
	text-align-last: center;
}

.EditHouseDropDown {
	text-align-last: center;
}

.submitAmount {
	width: 150px;
	margin: 0 10px;
	border: none;
	border-radius: 5px;
	text-align-last: center;
	margin: 5px;
}

.ui.form select {
	width: 250px;
	height: 40px;
	margin-bottom: 10px;
}

.ui.large.form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.edit-date-entry {
	text-align-last: center;
	margin-bottom: 10px;
	width: 180px;
}

@media only screen and (max-width: 360px) {
	.crests {
		display: flex;
		justify-content: center;
		width: 90%;
	}

	.landing-page-crests,
	.landing-page-left-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	h1 {
		padding: 0;
		font-size: x-large;
		letter-spacing: 2px;
	}

	.numbers {
		font-size: large;
	}

	.login-button {
		padding: 5px 30px;
	}

	.register-button {
		padding: 5px 0px;
		margin-top: 10px;
	}

	.summary {
		padding: 20px;
		font-size: medium;
	}

	.Loader {
		padding: 0px;
	}

	.right-div {
		height: fit-content;
		min-height: fit-content;
		padding-bottom: 20px;
	}

	hr {
		margin-top: 20px;
	}
}

.linkedin-logo {
	padding-top: 0px;
}

.register-container input {
	margin: 2px;
}

.dashboard-error-messages {
	width: 350px;
	color: white;
	font-size: larger;
}

/* Test Styles */
.three-d-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 40px 20px;
}

* {
	box-sizing: border-box;
}

.card {
	width: 300px;
	height: 300px;
	position: relative;
	display: flex;
	align-items: flex-end;
	perspective: 2500px;
	margin: 30px 20px;
}

.cover-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px !important;
}

.wrapper {
	transition: all 0.5s;
	position: absolute;
	width: 100%;
	z-index: -1;
	border-radius: 10px !important;
	padding: 20px;
	text-align: left;
	background-image: linear-gradient(62deg, #fbac7e00 0%, #f7cf6869 100%);
}

.card:hover .wrapper {
	transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
	box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper::before,
.wrapper::after {
	content: "";
	opacity: 0;
	width: 100%;
	height: 80px;
	transition: all 0.5s;
	position: absolute;
	left: 0;
	border-radius: 10px !important;
}

.wrapper::before {
	top: 0;
	height: 100%;
	background-image: linear-gradient(
		to top,
		transparent 46%,
		rgba(12, 13, 19, 0.5) 68%,
		rgba(12, 13, 19) 97%
	);
}
.wrapper::after {
	bottom: 0;
	opacity: 1;
	background-image: linear-gradient(
		to bottom,
		transparent 46%,
		rgba(12, 13, 19, 0.5) 68%,
		rgba(12, 13, 19) 97%
	);
}

.card:hover .wrapper::before,
.wrapper::after {
	opacity: 1;
}

.card:hover .wrapper::after {
	height: 120px;
}
.title {
	width: 100%;
	transition: transform 0.5s;
}
.card:hover .title {
	transform: translate3d(0%, -50px, 100px);
}

.character {
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
	position: absolute;
	z-index: -1;
}

.card:hover .character {
	opacity: 1;
	transform: translate3d(0%, -30%, 100px);
}

/* PEEPERS - START */
.container {
	display: flex;
	width: 100%;
	padding-bottom: 20px;
}
.eye {
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	background: #ccc;
	margin: 2px;
}
.eye:after {
	position: absolute;
	bottom: 17px;
	right: 10px;
	width: 10px;
	height: 10px;
	background: #000;
	border-radius: 50%;
	content: " ";
}
/* PEEPERS - END */
.welcome-message {
	color: white;
	width: 100%;
	text-transform: capitalize;
	font-size: 20px;
}

.logo-and-welcome-message {
	display: flex;
	align-items: center;
}

.no-dashboards {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.boards-error-bug {
	min-width: 100px;
}

.board-title {
	font-size: 18px;
	text-transform: capitalize;
	padding: 10px;
	font-style: bolder;
	color: orange;
}

.create-dashboard-page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
	min-height: 100vh;
}

.board-points-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-top: 120px;
}

.board-points-card {
	max-width: 300px;
	position: relative;
	align-items: flex-end;
	perspective: 2500px;
	margin: 40px 20px;
}

.board-points-card:hover {
	cursor: pointer;
}

.points-board-title {
	text-align: left !important;
	font-size: 18px;
	text-transform: capitalize;
	font-style: bolder;
	color: orange;
	width: 100%;
	padding-top: 20px;
}

.points-board-description,
.board-card-character {
	font-size: 14px;
	color: white;
	max-width: 180px;
	padding-left: 10px;
}

.no-points-title {
	text-align: left !important;
	font-size: 18px;
	text-transform: capitalize;
	font-style: bolder;
	color: orange;
	width: 100%;
	padding-top: 20px;
	padding: 20px 20px 0;
}

.no-points-message {
	color: white;
	max-width: 650px;
	padding: 0 30px 30px;
	text-align: left;
}

.add-points-container {
	padding: 0 40px !important;
}

.points-board-description-and-id {
	display: flex;
	justify-content: space-between;
}

.dashboard-container {
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
}

.edit-point-container {
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.edit-point-form-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.edit-points-form,
.edit-board-form,
.login-form-container,
.register-form-container {
	background-color: rgba(79, 79, 79, 0.765);
	border-radius: 10px;
	margin: 20px;
	color: rgb(85, 85, 85);
	padding: 0 40px 40px;
	max-width: 450px !important;
	width: 100% !important;
}

.login-form-container,
.register-form-container {
	padding: 0 60px 60px !important;
	color: white;
}

.add-new-user-form {
	padding-bottom: 100px !important;
}

.form-input-field::placeholder {
	color: lightgray !important;
}

.update-and-delete-points {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}

.edit-points-form-button {
	background: orange;
	border: 1px solid orange;
	padding: 15px;
	width: 340px;
	color: white;
	cursor: pointer;
	border-radius: 40px;
	font-size: 15px;
	transition: 600ms;
	margin: 10px;
}

.update-delete-point-button {
	padding: 10px 50px !important;
}

.dashboard-profile-image {
	max-width: 350px;
}

.points-dashboard-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 800px;
}

.points-dashboard-menu {
	max-width: 800px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.add-point-board-description {
	color: white;
	max-width: 450px;
	min-height: 15px;
}

.board-description {
	font-size: 16px;
	color: white;
	max-width: 650px;
	text-align: left;
	padding-left: 15px;
}

.add-points-section {
	padding: 40px;
}

.points-board-nav {
	display: flex;
}

.back-to-main-dashboard,
.board-settings {
	padding: 15px;
	max-width: 320px;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	font-size: 15px;
	transition: 600ms;
	margin: 10px;
}

.back-to-main-dashboard {
	background: rgba(255, 166, 0, 0.454);
	border: none;
}

.board-settings {
	background-color: rgba(0, 128, 0, 0.517);
	border: none;
}

.points-settings-wrapper {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}

.board-name {
	font-size: 40px;
	color: white;
	font-weight: 900;
	text-align: left;
	padding-left: 15px;
}

.board-details {
	padding: 20px;
}

.ui.progress {
	margin: 10px 10px !important;
}

.delete-and-redirect-buttons {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
}

.edit-board-back-to-dashboard {
	background-color: rgba(0, 128, 0, 0.517);
	border: none;
	padding: 10px 50px;
	color: white;
	cursor: pointer;
	border-radius: 10px;
	font-size: 15px;
	transition: 600ms;
}

.delete-board-button {
	background: rgba(255, 34, 0, 0.454);
	border: none;
	padding: 10px 40px;
	color: white;
	cursor: pointer;
	border-radius: 10px;
	font-size: 15px;
	transition: 600ms;
}

.grid {
	margin: 50px;
}

.edit-points-back-update-delete {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.edit-points-dropdown-input {
	width: 100%;
	padding-bottom: 10px !important;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
	color: #000;
}

.edit-points-form img {
	max-width: 250px;
}

.edit-points-form input,
textarea {
	margin: 5px !important;
}

.edit-board-dropdown-input {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	padding-bottom: 20px !important;
}

.edit-points-back-update-delete Button {
	margin: 0 !important;
}

.submit-points {
	padding: 10px 40px !important;
	margin: 4px !important;
}
